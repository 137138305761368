import { myAxios } from "./myAxios";
import { WEBSITE_PATH } from "../constants/path";

export const getAllWebsite = async () => {
  const path = `${WEBSITE_PATH}/all`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

interface Data {
  subdomain: string;
}

export const updateWebsiteSubdomain = async (websiteId: string, data: Data) => {
  const path = `${WEBSITE_PATH}/site/${websiteId}/subdomain`;
  const res = await myAxios.put({ path, data });
  return res.data;
};

export const updateWebsite = async (websiteId: string, data: Data) => {
  const path = `${WEBSITE_PATH}/site/${websiteId}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};

export const getWebsiteByAccommoId = async (accommodationId: string) => {
  const path = `${WEBSITE_PATH}/accommo/${accommodationId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};
