import { getAllWebsite, updateWebsiteSubdomain } from "api/website";
import React from "react";
import styled from "styled-components";
import { formatSubdomain } from "./utils/formatSubdomain";

type Props = {};

export default function WebsiteDashboard({}: Props) {
  const [websites, setWebsites] = React.useState([]);
  React.useEffect(() => {
    const fetchAllWebsite = async () => {
      const res = await getAllWebsite();
      setWebsites(res);
    };
    fetchAllWebsite();
  }, []);

  if (websites.length === 0) return <div>Loading...</div>;
  return (
    <S.Container>
      <div>🚨🚨🛎️ 서브도메인 변경시 영소문자, 숫자, - 만 가능합니다.</div>
      <br />
      {websites.map((website: any) => (
        <Item key={website._id} website={website} />
      ))}
    </S.Container>
  );
}

function Item({ website }) {
  const [subdomain, setSubdomain] = React.useState(website.subdomain);

  const handleSubmit = async () => {
    if (!subdomain) return alert("서브도메인을 입력해주세요");

    try {
      await updateWebsiteSubdomain(website._id, { subdomain });
      alert("수정 완료");
    } catch (err) {
      alert("수정할 수 없습니다 :p");
    }
  };
  return (
    <S.Box>
      <S.Col1>
        <S.Text>{website.accommo.name}</S.Text>
        <a
          href={`https://${subdomain}.finestay.ai`}
          target="_blank"
          rel="noreferrer"
        >
          https://<span id="subdomain">{subdomain}</span>.finestay.ai
        </a>
      </S.Col1>
      <S.Col2>
        <input
          value={subdomain}
          onChange={(e) => {
            const value = formatSubdomain(e.target.value);
            setSubdomain(value);
          }}
        />
        <button onClick={handleSubmit}>수정</button>
      </S.Col2>
    </S.Box>
  );
}

const S = {
  Container: styled.div``,
  Box: styled.div`
    border-bottom: 1px solid #e0e0e0;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `,
  Text: styled.div`
    #subdomain {
      color: blue;
    }
  `,
  Col1: styled.div``,
  Col2: styled.div`
    margin-left: 20px;
  `,
};
