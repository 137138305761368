export const CONSULTS_PATH = "/consults";
export const CHAT_PATH = "/chat";
export const MSGS_PATH = "/messages";
export const CONSULT_INFO_PATH = (consultId: string) =>
  `/consults/${consultId}/clientInfo`;
export const SMS_COMMON_PATH = "/sms/common";
export const MSG_TEMPLATE_PATH = "/messages/template";
export const FORM_RESPONSE_PATH = "/formResponses";
export const PACKAGE_PLACE_PATH = "/package-places";
export const PACKAGE_PATH = "/packages";
export const PACKAGE_IMAGE_PATH = "/files/package";
export const SHORTS_VIDEO_PATH = "/files/shortsPostVideo";
export const CLEANING_IMAGE_PATH = "/files/cleaning";
export const CHAT_FILE_PATH = "/files/chat";
export const USER_PATH = "/users";
export const FINEHOST_USER_PATH = "/finehost/user";
export const LODGMENT_PATH = "/lodgments";
export const SHORTS_PATH = "/shortsPosts";
export const CLEANING_SCHEDULE_PATH = "/cleaningSchedules";
export const SETTLEMENT_LOG_PATH = "/settlementLogs";
export const LODGMENT_RESERVATION_PATH = "/lodgmentReservations";
export const CORPORATION_PATH = "/corporation";
export const HAULINK_PATH = "/haulink";
export const PREOPEN_PATH = "/preopen";
export const HAU_DATA_PATH = "/hauData";
export const PREOPEN_MULTIPLE_PATH = "/preopenMultiple";

export const LODGMENT_RESERVATION_REFUSE_LOG_PATH =
  "/lodgmentReservationRefuseLog";

export const SMART_PLACE = "/smartPlace/businesses";

export const PUSH_PATH = "/push";
export const NOTIFICATION_PATH = "/notification";

export const WEBSITE_PATH = "/websites";
export const ACCOMMO_PATH = "/accommo"
